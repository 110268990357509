var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "workflow-audit" }, [
    _c("div", { staticClass: "form-area" }, [
      _vm.viewMode
        ? _c("div", { staticClass: "detail-inner" }, [
            _c(
              "div",
              { class: _vm.taskViewMode ? "detail-inner" : "form-inner" },
              [
                _c(
                  "el-form",
                  {
                    ref: "auditForm",
                    attrs: {
                      model: _vm.auditForm,
                      rules: _vm.rules,
                      "label-width": "120px",
                      "label-position": "left",
                      "label-suffix": "："
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "detail-inner" },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 80 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "btnCode",
                                      label: "审批结果"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: { change: _vm.btnCodeChange },
                                        model: {
                                          value: _vm.auditForm.btnCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.auditForm,
                                              "btnCode",
                                              $$v
                                            )
                                          },
                                          expression: "auditForm.btnCode"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "pass" } },
                                          [_vm._v(" 通过 ")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "back" } },
                                          [_vm._v(" 驳回 ")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "refusal" } },
                                          [_vm._v(" 关闭 ")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "审批意见",
                                      prop: "taskData.remarks"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入审批意见(限300字)",
                                        maxlength: 300,
                                        type: "textarea"
                                      },
                                      model: {
                                        value: _vm.auditForm.taskData.remarks,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.auditForm.taskData,
                                            "remarks",
                                            $$v
                                          )
                                        },
                                        expression: "auditForm.taskData.remarks"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              padding: "0px 0px 20px",
                              margin: "0 auto",
                              "text-align": "center",
                              "margin-top": "20px"
                            },
                            attrs: { span: 24 }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "add-btn",
                                attrs: { type: "primary" },
                                on: { click: _vm.commit }
                              },
                              [_vm._v(" 提交 ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e()
    ]),
    !_vm.viewMode
      ? _c(
          "div",
          { staticClass: "audit-result-list" },
          [
            _c(
              "table-inner",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading.detail,
                    expression: "loading.detail"
                  }
                ],
                attrs: {
                  title: _vm.title || "审批列表",
                  "table-footer": false,
                  "table-data": _vm.taskDetail.processDetailInfoVoList
                }
              },
              [
                _c(
                  "template",
                  { slot: "table-columns" },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "recordTime",
                        label: "时间",
                        align: "center",
                        formatter: _vm.utils.isEffective,
                        "show-overflow-tooltip": "",
                        "min-width": "150"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "applyState",
                        label: "业务状态",
                        align: "center",
                        formatter: _vm.utils.isEffective,
                        "show-overflow-tooltip": "",
                        "min-width": "150"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "operationName",
                        label: "操作人",
                        align: "center",
                        formatter: _vm.utils.isEffective,
                        "show-overflow-tooltip": "",
                        "min-width": "150"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "content",
                        label: "操作",
                        align: "center",
                        formatter: _vm.utils.isEffective,
                        "show-overflow-tooltip": "",
                        "min-width": "150"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "operationAnnotation",
                        label: "备注",
                        align: "center",
                        formatter: _vm.utils.isEffective,
                        "show-overflow-tooltip": "",
                        "min-width": "150"
                      }
                    })
                  ],
                  1
                )
              ],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }