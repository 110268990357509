<template>
  <div class="workflow-audit">
    <div class="form-area">
      <div v-if="viewMode" class="detail-inner">
        <div :class="taskViewMode ? 'detail-inner' : 'form-inner'">
          <el-form ref="auditForm" :model="auditForm" :rules="rules" label-width="120px" label-position="left" label-suffix="：">
            <div class="detail-inner">
              <el-row :gutter="80">
                <el-col :span="12">
                  <el-form-item prop="btnCode" label="审批结果">
                    <el-radio-group v-model="auditForm.btnCode" @change="btnCodeChange">
                      <el-radio label="pass">
                        通过
                      </el-radio>
                      <el-radio label="back">
                        驳回
                      </el-radio>
                      <el-radio label="refusal">
                        关闭
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="审批意见" prop="taskData.remarks">
                    <el-input v-model="auditForm.taskData.remarks" placeholder="请输入审批意见(限300字)" :maxlength="300" type="textarea" />
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-row :gutter="80">
              <el-col :span="24" style="padding: 0px 0px 20px;margin:0 auto; text-align: center; margin-top: 20px;">
                <el-button class="add-btn" type="primary" @click="commit">
                  提交
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <div v-if="!viewMode" class="audit-result-list">
      <table-inner v-loading="loading.detail" :title="title || '审批列表'" :table-footer="false" :table-data="taskDetail.processDetailInfoVoList">
        <template slot="table-columns">
          <el-table-column prop="recordTime" label="时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="applyState" label="业务状态" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="operationName" label="操作人" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="content" label="操作" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="operationAnnotation" label="备注" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        </template>
      </table-inner>
    </div>
  </div>
</template>

<script>
class AuditProp {
  btnCode = ''
  btnName = ''
  btnStatus = ''
  opinion = ''
  taskData = {
    flowState: '',
    btnStatus: '',
    remarks: ''
  }
}
export default {
  name: 'WorkflowAudit',
  props: {
    title: {
      type: String,
      default: ''
    },
    // 展示模式
    viewMode: {
      type: Boolean,
      default: false
    },
    // 隐藏审核记录
    hideResultList: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: String,
      default: ''
    },
    procInstId: {
      type: String,
      default: ''
    },
    businessKey: {
      type: [String, Number],
      default: ''
    },
    // 附加参数
    variable: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      auditForm: new AuditProp(),
      taskDetail: {
        processDetailInfoVoList: []
      },
      rules: {
        btnCode: [
          { required: true, message: '请选择审批结果', trigger: 'change' }
        ],
        taskData: {
          remarks: [
            {required: true, message: '请输入审批意见', trigger: 'blur'}
          ]
        }
      },
      dialog: {
        userList: false
      },
      loading: {
        list: false,
        confirm: false,
        submit: false,
        detail: false
      }
    }
  },
  computed: {
    taskViewMode () {
      if (this.viewMode) {
        return this.viewMode
      } else return !!(this.$route.query.taskViewMode && Number(this.$route.query.taskViewMode) === 1)
    }
  },
  watch: {
    businessKey: {
      handler: 'init',
      immediate: true
    }
  },
  methods: {
    init () {
      if (this.businessKey) {
        this.getTaskDetail()
      }
    },
    getTaskDetail () {
      this.api.operating.listPage({ businessId: this.businessKey, pageSize: 99999 }).then(result => {
        this.taskDetail.processDetailInfoVoList = result.data.data.list
      }).catch(e => {
      })
    },
    btnCodeChange (value) {
      switch (value) {
        case 'pass':
          this.auditForm.btnName = '通过'
          this.auditForm.taskData.flowState = 'Commit'
          this.auditForm.taskData.flowName = '平台审批'
          this.auditForm.btnStatus = '已审核'
          break
        case 'back':
          this.auditForm.btnName = '驳回'
          this.auditForm.taskData.flowState = 'Commit'
          this.auditForm.taskData.flowName = '平台审批'
          this.auditForm.btnStatus = '已驳回'
          break
        case 'refusal':
          this.auditForm.btnName = '拒绝'
          this.auditForm.taskData.flowState = 'Commit'
          this.auditForm.taskData.flowName = '平台审批'
          this.auditForm.btnStatus = '已拒绝'
          break
        default:
          this.auditForm.btnName = ''
          this.auditForm.btnStatus = ''
          this.auditForm.taskData.flowState = 'Commit'
          this.auditForm.taskData.flowName = '平台审批'
      }
    },
    commit () {
      this.$refs.auditForm.validate().then(() => {
        this.$emit('submit', this.auditForm)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.workflow-audit {
  margin-top: 30px;
  .btn-inner {
    text-align: center
  }
}
</style>
